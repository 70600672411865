.about-wrapper{
    width: 100%;
    margin: 0;
    margin-top: 150px;
    margin-bottom: 100px;
}
.about-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.about-heading>h1 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 44px;
    line-height: 47px;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
    font-family: Inter, sans-serif;
    color: #0a1f44;
}

.about-heading>h3 {
    margin: 0;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    font-family: Inter, sans-serif;
    color: #333;
    font-weight: 100;
    letter-spacing: -.01em;
    margin-bottom: 60px;
}
.about-img{
    padding: 0 5% 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.about-img>img{
    width: 100%;
    height: 500px;
}