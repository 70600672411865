.demo-wrapper{
    background-color: #41c9f3;
    min-height: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-image: url(../../img/demo.png);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}
.demo-wrapper>h1{
    margin: 0 auto;
    color: #f5f7fd;
    font-size: 34px;
    line-height: 41px;
    text-align: center;
    font-family: Inter,sans-serif;
    font-weight: 700;
    width: 40%;
}
.demo-wrapper>button{
    display: flex;
    max-width: 220px;
    min-height: 45px;
    margin-top: 25px;
    padding: 16px 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background-color: #6836db;
    transition: 350ms;
    color: #fff;
    font-size: 12px;
    line-height: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    outline: none;
    border: none;
}