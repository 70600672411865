.contact-wrapper {
    width: 100%;
    margin: 0;
    margin-top: 150px;
    margin-bottom: 100px;
}

.contact-t {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contact-t-head>h1 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 44px;
    line-height: 47px;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
    font-family: Inter, sans-serif;
    color: #0a1f44;
}

.contact-t-head>h3 {
    margin: 0;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    font-family: Inter, sans-serif;
    color: #333;
    font-weight: 100;
    letter-spacing: -.01em;
    margin-bottom: 60px;
}

.contact-t-main {
    position: relative;
    width: 60%;
    height: 100%;
    max-width: 1000px;
    padding: 30px 30px 15px;
    border: 1px solid #ebecf0;
    border-radius: 10px;
    box-shadow: 14px 14px 40px 0 rgb(118 126 173 / 8%);
    margin-bottom: 100px;
}

.contact-t-main>:nth-child(1) {
    display: flex;
    justify-content: space-between;
}

.contact-t-main>:nth-child(1)>input {
    width: 46%;
    border: 1.5px solid #ebecf0;
    border-radius: 4px;
    background-color: #fff;
    color: #8a94a6;
    font-size: 16px;
    font-weight: 400;
    display: block;
    padding: 8px 12px;
    margin-bottom: 10px;
    line-height: 1.42857143;
    outline: none;
}

.contact-t-main>:nth-child(2)>input  ,.contact-t-main>:nth-child(2)>textarea {
    width: 96.6%;
    border: 1.5px solid #ebecf0;
    border-radius: 4px;
    background-color: #fff;
    color: #8a94a6;
    font-size: 16px;
    font-weight: 400;
    display: block;
    padding: 8px 12px;
    margin-bottom: 10px;
    line-height: 1.42857143;
    outline: none;
}
::placeholder{
    color: #8a94a6;
}
.contact-t-main>:nth-child(3)>button{
    width: 100%;
    padding-top: 14px;
    padding-bottom: 14px;
    border-radius: 4px;
    background-color: #e51260;
    color: #fff;
    font-size: 13px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    outline: none;
    border: none;
}
.contact-b{
    display: flex;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
}
.contact-b-l{
    width: 50%;
    height: fit-content;
    display: flex;
    justify-content: center;
    padding: 1rem;
}
.contact-map{
    width: 600px;
}
.contact-map>img{
    width: 100%;
    height: 330px;
}
.contact-b-right{
    width: 50%;
}
.contact-items{
    display: flex;
    margin: 1rem;
}
.contact-icon{
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    border-radius: 100px;
    background-color: #fff;
    box-shadow: 6px 6px 13px 0 rgb(164 174 175 / 17%);
    justify-content: center;
    align-items: center;
    margin: 0rem 2rem;
}
.contact-icon>img{
    width: 20px;
}
.contact-text{
    margin: 0;
    display: flex;
    flex-direction: column;
}
.contact-text>h2{
    margin: 0;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 25px;
    color: #0a1f44;
    font-weight: 700;
}
.contact-text>h4{
    margin: 0;
    display: block;
    margin-bottom: 15px;
    line-height: 26px;
    font-family: Inter,sans-serif;
    color: #4e5d78;
    font-size: 16px;
    font-weight: 400;
    width: 80%;
}
.contact-btn{
    margin: 0rem 6rem;
}
.google-btn{
    display: flex;
    max-width: 150px;
    min-height: 30px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(23,48,73,.18);
    border-radius: 100px;
    background-color: #7540ee;
    transition: .2s;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
}