.pricing-wrapper {
    width: 100%;
    margin: 0;
    margin-top: 150px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pricing-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
}

.pricing-heading>h1 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 44px;
    line-height: 47px;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
    font-family: Inter, sans-serif;
    color: #0a1f44;
}

.pricing-heading>h2 {
    margin: 0;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -.01em;
    font-family: Inter, sans-serif;
    color: #333;
    font-weight: 200;
}

.price {
    padding: 90px 6%;
    display: flex;
}

.price-left {
    width: 35%;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 8px;
}

.price-left>h1 {
    max-width: 500px;
    margin-right: auto;
    margin-bottom: 4px;
    color: #8a94a6;
    font-size: 14px;
    line-height: 25px;
    font-weight: 700;
    text-align: left;
    letter-spacing: 1px;
    font-family: Inter, sans-serif;
    margin-top: 0;
}

.price-left>h3 {
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    color: #0a1f44;
    font-size: 38px;
    line-height: 40px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 10px;
    font-family: Inter, sans-serif;
}

.price-left>h4 {
    font-size: 38px;
    line-height: 40px;
    font-weight: 700;
    text-align: left;
    color: #e51260;
    margin-bottom: 10px;
}

.price-left>h5 {
    max-width: 500px;
    margin-bottom: 18px;
    font-size: 16px;
    line-height: 25px;
    text-align: left;
    margin-top: 0;
    font-family: Inter, sans-serif;
    color: #4e5d78;
}

.price-right {
    display: flex;
    width: 65%;
}

.price-box {
    width: 49%;
    height: auto;
    padding: 35px 20px 20px 30px;
    border: 1px solid #ebecf0;
    border-radius: 8px;
    background-color: #fff;
    margin: 0rem 0.5rem;
}

.price-box>h1 {
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    color: #0a1f44;
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 10px;
    font-family: Inter, sans-serif;
}

.price-box>ul {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 10px;
    font-family: Inter, sans-serif;
}

.price-box>ul>li {
    color: #4e5d78;
    font-size: 16px;
    margin-bottom: 10px;
}

.price-box>h2 {
    margin: 20px 0 10px;
    font-family: Inter, sans-serif;
    line-height: 1;
    font-weight: 700;
    text-align: left;
    color: #33334f;
    font-size: 36px;
    margin-bottom: 20px;
}

.price-box>h2>span {
    margin-left: 6px;
    color: #7540ee;
    font-size: 14px;
}

.price-btn {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    background-color: #41c9f3;
    box-shadow: 14px 14px 40px 0 rgb(118 126 173 / 8%);
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 1px;
    border: none;
    color: white;

}

.price-box>h3 {
    max-width: 200px;
    margin-top: 12px;
    margin-right: auto;
    margin-left: auto;
    color: #b0b7c3;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
}

