.Featured-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
}

.Featured-wrapper {
    width: 100%;
    background-color: white;
    margin-bottom: 5%;
}

.Featured-heading>h3 {
    margin: 0;
    color: #41c9f3;
    font-size: 14px;
    line-height: 25px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
}

.Featured-heading>h1 {
    margin: 0 auto 25px;
    color: #0a1f44;
    font-size: 36px;
    line-height: 45px;
    text-align: center;
}

.feature-boxes{
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    display: flex;
    justify-content: space-between;
}
.feature-box{
    width: 33.3%;
    height: fit-content;
    margin: 0rem 1rem;
}
.feature-img{
    width: 100%;
    background-color: #000;
}
.feature-img>img{
    width: 100%;
}
.feature-details{
    width: 100%;
}
.feature-details>h2{
    margin-bottom: 15px;
    color: #8a94a6;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.feature-details>h1{
    color: #0a1f44;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    text-decoration: none;
    width: 100%;
}
.feature-details>h6{
    margin-top: 10px;
    padding-right: 20px;
    color: #8a94a6;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
     margin: 0;
     padding: 0px 20px 0px 0px;
     width: 100%;

}
.feature-details>span{
    color: #e51260;
    font-size: 14px;
}
